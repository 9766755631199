import React from 'react'
import "./tabbtn.scss"
import $ from "jquery"


class TabButton extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            toggle : true
        }
        
    }
    componentDidMount(){
        this.toggle()
    }
    toggle = (e)=> {
    if(this.state.toggle) {
        let monthly =  document.querySelectorAll('#monthly');
            for(let i=0; i<monthly.length; i++) {
                if(monthly[i]) {monthly[i].style.display = monthly[i].style.display = "none"}
            }
        let annually =  document.querySelectorAll('#annually');
            for(let i=0; i<annually.length; i++) {
                if(annually[i]) {annually[i].style.display = annually[i].style.display = '' }
            }
    }
    else {
        let monthly =  document.querySelectorAll('#monthly');    
            for(let i=0; i<monthly.length; i++) {
                if(monthly[i]) {monthly[i].style.display = monthly[i].style.display = ""}
        }
        let annually =  document.querySelectorAll('#annually');
            for(let i=0; i<annually.length; i++) {
                if(annually[i]) {annually[i].style.display = annually[i].style.display = 'none' }
        }
    }

    
    this.setState({toggle: !this.state.toggle})
    var taeb = $(".taeb-switch");
    taeb.find(".taeb").on("click", function() {
    var $this = $(this);

    if ($this.hasClass("active")) 
    return;
    var direction = $this.attr("taeb-direction");

    taeb.removeClass("left right").addClass(direction);
    taeb.find(".taeb.active").removeClass("active");
    $this.addClass("active");
});

}

render() {
    return(
            <div className="tab-style">
                <div className="wrapper">
                    <div  className="taeb-switch left text-center">
                        <div onClick={this.toggle} className="taeb active" taeb-direction="left">Monthly</div>
                        <div onClick={this.toggle} className="taeb" taeb-direction="right">Annually <span style={{color: '#3abd82', fontSize:'12px'}}>(Save More)</span></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TabButton