import React from 'react'
import Layout from '../components/layout'
import MetaTags from '../components/Metatags'
import GetStarted from '../components/getstarted'
import Pricing from "../components/pricing"
import Cta from "../components/cta"
import TabButton from '../components/tabbutton'

class PricingPage extends React.Component {
    render() {
        return (
            <Layout>
                <MetaTags
                    title="Optinly Pricing Plans | Use the Powerful Popup Builder for Free Today"
                    description="Optinly’s pricing plans are suitable for businesses of all size. There’s also a forever free plan for you to try it out. Know more about Optinly’s pricing plans on this page."
                    keywords="website popups price, ecommerce popup pricing, online store optins, lightbox popups plan"
                />
                <div className="pricing-banner-container">
                    <div className="breadcrumb_top">
                        <div className="container">
                            <div className="text-center">
                                <h2>Grow Your Email List and Drive Conversions 2x Times</h2>
                                <p>Install Optinly now to increase sales and revenue in your store!</p>
                                <Cta url="https://app.optinly.com" rel="noopener" target="_blank">Get Started for Free</Cta>
                                <br/>
                                <p>Simple, Honest Pricing. No Hidden Charges!</p>
                            </div>
                        </div>
                    </div>
                    <div id="pricing" className="pricing-section text-center pricing-plans-bg">
                       
                        <div id="pricing-toggle" className="py-3">
                            <TabButton/>
                        </div>

                        <Pricing/>
                        
                    </div>
                    <GetStarted/> 
                </div>
            </Layout>
        )
    }
}

export default PricingPage
